.aboutView h4 {
  color: #4eb060;
  font-size: 22px;
  margin-top: 40px;
}
.aboutView p {
  padding-top: 20px;
  letter-spacing: 1px;
  font-size: 16px;
  width: 80%;
  padding-bottom: 30px;
}
@media (max-width: 900px) {
    .aboutView p {
      width: 100%;
    }
  }
.flexClass {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@media (max-width: 900px) {
  .flexClass {
    flex-wrap: wrap;
  }
}
@media (max-width: 800px) {
  .imgClass img{
    /* width: 80%; */
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.newh4{
    margin-left: 50px;
}
@media(max-width:800px){
    .newh4{
    margin-left: 0px;
}}